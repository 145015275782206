/** @format */

@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);

/* ///////////////// common \\\\\\\\\\\\\\\\\\\\\\\\ */
.font-aspekta {
  font-family: var(--font-Aspekta) !important;
}
.font-aspekta-300 {
  font-family: var(--font-Aspekta-300) !important;
}
.font-aspekta-350 {
  font-family: var(--font-Aspekta-350) !important;
}
.font-aspekta-400 {
  font-family: var(--font-Aspekta-400) !important;
}
.font-aspekta-450 {
  font-family: var(--font-Aspekta-450) !important;
}
.font-aspekta-500 {
  font-family: var(--font-Aspekta-500) !important;
}
.font-aspekta-600 {
  font-family: var(--font-Aspekta-600) !important;
}
.font-aspekta-700 {
  font-family: var(--font-Aspekta-700) !important;
}

.aspektaInput input[type='text'],
.aspektaInput input[type='number'],
.aspektaInput input::placeholder {
  font-family: var(--font-Aspekta-450) !important;
  color: #3a4a5f !important;
  font-size: 13px !important;
}

.bgImage {
  background-image: url('solfen/assets/landing_bg_img.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* black with 50% opacity */
}
/* ///////////////// common \\\\\\\\\\\\\\\\\\\\\\\\ */
.App {
  text-align: center;
}

.snip1205 {
  position: relative;
  overflow: hidden;

  width: 100%;
  background: #000000;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.snip1205 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.snip1205 img {
  max-width: 100%;
  vertical-align: top;
}
.snip1205 i {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  font-size: 34px;
  color: #000000;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  transition: all 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.snip1205:hover img,
.snip1205.hover img {
  opacity: 0.3;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.snip1205:hover i,
.snip1205.hover i {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  transition: all 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border-color: lightgray !important;
  border-radius: 9px !important;
}

@font-face {
  font-family: 'GoogleSans'; /*Can be any text*/
  src: local(''), url('./fonts/GoogleSans-Regular-v1.27.ttf') format('truetype');
}
.active {
  background-color: #131432 !important;
  color: white !important;
}
.navLink li {
  padding-top: 0px;
  padding-bottom: 0px;
  /* border-left: 8px solid transparent; */
}
.navActive li {
  color: #08a6ff !important;
  /* border-left: 8px solid #08a6ff; */
}
.navActive li svg path {
  stroke: #08a6ff !important;
  /* border-left: 8px solid #08a6ff; */
}
.show {
  display: none;
  position: absolute;
  background-color: red;
  left: 10px;
}
.hoverState:hover .show {
  display: block !important;
}
.MuiDataGrid-cell {
  height: auto !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.draggable-list__item {
  /* padding: 10px 0; */
  border-top: 2px solid transparent;
  cursor: move;
}

.draggable-list__item * {
  pointer-events: none;
}

.draggable-list__item.dragstart {
  opacity: 0.5;
  border: 5px solid #131432;
}

.draggable-list__item.dragover {
  border-top-color: rgb(0, 10, 0);
}
.yellow-header {
  background-color: #e7f4d2 !important;
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
/* // switch css \\ */

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 24px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff0000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2ab934;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(45px);
  -ms-transform: translateX(45px);
  transform: translateX(45px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 35%;
  font-size: 10px;
}
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 67%;
  font-size: 10px;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.customInput {
  font: inherit;
  width: 100%;
  border: none;
  /* padding: 5px; */
}
.customInput:focus {
  outline: none;
}
.bouncingImg {
  position: relative;
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  height: 200px;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 200px;
}
.bouncingImg {
  animation-name: bounce-1;
  animation-timing-function: linear;
}
@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid #131432; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* /////////////////////////////// table \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
.radiusTable thead tr th:first-child {
  border-top-left-radius: 20px;
}
.radiusTable thead tr th:last-child {
  border-top-right-radius: 20px;
}
.radiusTable tbody tr:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
/* /////////////////////////////// table \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
/* //////////////////////////// button Hover \\\\\\\\\\\\\\\\\\\\\\\ */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  opacity: 0;
  transition: 0.3s all;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media:hover img {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}
.media:hover .overlay {
  opacity: 1;
}
/* //////////////////////////// button Hover \\\\\\\\\\\\\\\\\\\\\\\ */

/* ////////////////////////  table \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
.LOStable tbody td {
  color: #181f28;
  font-size: 10;
  padding: 10px;
  text-align: start;
}
.LOStable tbody tr {
  height: 56px;
}
.LOStable thead,
.LOStable tbody tr {
  border-bottom: 1px solid #e1e4eb !important  ;
}
.LOStable thead tr th p {
  color: #676b76 !important;
  font-family: var(--font-Aspekta-700) !important;
}
/* ////////////////////////  table \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
